<script>
import Base from '@backend/Base.vue';
import Gen from '@helper/Gen';

export default {
	name: "BoPanduan",
	extends: Base,
  data(){
    return {
		Name:"BoPanduan",
    }
  },
  mounted(){
    this.$set(this.$root, 'page', this)
		this.refreshData(()=>{
    })
  },
  methods:{
  },
  watch:{
    '$route.query'(v){
			this.refreshData()
		}
  }
}
</script>

<template>
	<div class="container-fluid">
		<div class="page-titles">
		      <div class="row">
		        <div class="col-md-5 align-self-center">
		          <h4 class="text-themecolor">Documentation</h4>
		      </div>
		      <div class="col-md-7 align-self-center text-right">
		          <div class="d-flex justify-content-end align-items-center">
		              <ol class="breadcrumb">
		                  <li class="breadcrumb-item"><router-link :to="{name:'BoDashboard'}">Home</router-link></li>
		              </ol>
		          </div>
		      </div>
		      </div>
		      
		  </div>
		<div class="card">
			<div class="card-body how_to">
				<h4 class="card-title">Tentang API Management</h4>
				<p>API Management adalah serangkaian proses yang memungkinkan bisnis untuk memiliki kontrol dan visibilitas ke dalam API dari pihak ketiga untuk mengatur kuota penggunaannya.</p>
				<p>Untuk menggunakan aplikasi ini, Anda harus mengatur beberapa hal berikut:</p>
				<ol>
					<li>Menambah API.</li>
					<li>Menyiapkan service.</li>
					<li>Menambah client.</li>
					<li>Mengatur quota.</li>
				</ol>
				<hr>
				<h4 class="card-title">Menambah API</h4>
				<p>API adalah tempat Anda untuk menyimpan data API yang akan digunakan. Untuk menambah API:</p>
				<ol>
					<li>Klik submenu <router-link :to="{name:'MrApi'}">API</router-link>.</li>
					<li>Klik tombol <strong>Add API</strong> di pojok kanan atas.</li>
					<li>Lalu isi form Add API dengan benar sesuai field yang tersedia. <br><small class="text-danger"><i>Field yang memiliki tanda bintang merah adalah wajib diisi.</i></small></li>
					<li>Klik <strong>Submit</strong>.</li>
				</ol>
				<hr>
				<h4 class="card-title">Menyiapkan Service</h4>
				<p>Service adalah tempat untuk menyiapkan endpoint yang akan terintegrasi antara Client dan Vendor. Untuk menambah service:</p>
				<ol>
					<li>Klik submenu <router-link :to="{name:'MrService'}">Service</router-link>.</li>
					<li>Klik tombol <strong>Add Service</strong> di pojok kanan atas.</li>
					<li>Lalu isi form Add Service dengan benar sesuai field yang tersedia.<br><small class="text-danger"><i>Field yang memiliki tanda bintang merah adalah wajib diisi.</i></small></li>
					<li>Klik <strong>Submit</strong>.</li>
				</ol>
				<hr>
				<h4 class="card-title">Menambah Client</h4>
				<p>Client adalah tempat Anda untuk menyimpan data bisnis/perusahaan/klien mana saja yang akan menggunakan API. Untuk menambah client:</p>
				<ol>
					<li>Klik submenu <router-link :to="{name:'MrClient'}">Client</router-link>.</li>
					<li>Klik tombol <strong>Add Client</strong> di pojok kanan atas.</li>
					<li>Lalu isi form Add Client dengan benar sesuai. <br><small class="text-danger"><i>Field yang memiliki tanda bintang merah adalah wajib diisi.</i></small></li>
					<li>Klik <strong>Submit</strong>.</li>
				</ol>
				<hr>
				<h4 class="card-title">Mengatur Quota API</h4>
				<p>Quota harus diatur untuk mengontrol pemakaian API oleh client. Untuk menyiapkan quota:</p>
				<ol>
					<li>Klik menu <router-link :to="{name:'AppManagementQuota'}">Quota management</router-link>.</li>
					<li>Klik tombol Add Quota Management di pojok kanan atas.</li>
					<li>Lalu isi form Add Quota Management dengan benar sesuai.<br><small class="text-danger"><i>Field yang memiliki tanda bintang merah adalah wajib diisi.</i></small></li>
					<li>Pilih tipe quota (Unlimited/Limited). Jika Anda ingin membatasi pemakaiannya, jangan mencentang Unlimited dan isi seberapa banyak kuota yang akan digunakan pada field Qty. Quota.</li>
					<li>Klik <strong>Submit</strong>.</li>
				</ol>
				<hr>
				<h4 class="card-title">Melihat Endpoint API</h4>
				<p>Untuk melihat endpoint API yang akan digunakan:</p>
				<ol>
					<li>Klik menu <router-link :to="{name:'AppManagementQuota'}">Quota Management</router-link>.</li>
					<li>Pada kolom Action, klik ikon mata (detail).</li>
					<li>Muncul popup Detail Quota.</li>
				</ol>

			</div>
		</div>
	</div>
</template>
